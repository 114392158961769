import React, { useState } from "react"
import PropTypes from "prop-types"
import Image from "../../custom/Image"
import Video from "../../../images/svgs/video-white-icon.svg"
import Document from "../../../images/svgs/documentresWhite.svg"
import ImageIcon from "../../../images/svgs/image-white-icon.svg"

import Style from "./style.module.scss"
import ResourcePopup from "../../resource/ResourcePopup"

// !definition of component
/**
 * @param props --> resource
 * @description --> Show uploaded resources in subjective answer
 * @returns SubjectiveQuestionResources Component.
 */

const SubjectiveQuestionResources = (props) => {
  const [openResource, setOpenResource] = useState(false)
  const getTheImageType = () => {
    if (props.resource?.resource_content_type === "student video") {
      return Video
    } else if (props.resource?.resource_content_type === "student document")
      if (props.resource?.resource_data?.file_type === "0") {
        return ImageIcon
      } else return Document
  }

  return (
    <>
      <div
        onClick={() => {
          setOpenResource(true)
        }}
        className={`d-flex pointer align-items-center position-relative pl-2 pr-4 py-1 mr-2 ${Style.subjective_ques_resource}`}
      >
        <Image className={`${Style.resource_icon} mb-0 mr-2 object-fit-contain`} src={getTheImageType()} />
        <div>
          <p className={`mb-0 fs-10px ${Style.resource_name}`}>{props.resource?.name ? props.resource?.name : props.resource?.resource_data?.name}</p>
          <p className={`mb-0 fs-10px`}>Attached</p>
        </div>
      </div>
      {openResource && (
        <ResourcePopup
          data={props?.resource}
          onHide={() => {
            setOpenResource(false)
          }}
        />
      )}
    </>
  )
}
SubjectiveQuestionResources.propTypes = {
  resource: PropTypes.object.isRequired,
}

export default SubjectiveQuestionResources
