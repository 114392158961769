import React, { useEffect, useState, useContext } from "react"
import _ from "lodash"
import { Dropdown } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import { TrainingSocketContext } from "../../stores/socket/training_socket/trainingSocketProvider"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import { SET_ACTIVE_ACTIVITY_DATA, VIEW_MODERATION_RESOURCE, SHOW_TRIGGERED_RESOURCE } from "../../stores/classroom_store/ClassroomActions"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { LessonboardContext } from "../../stores/classroom_store/lessonboard_store/LessonboardStore"
import { SET_RESOURCE_DATA } from "../../stores/classroom_store/lessonboard_store/LessonboardActions"
import TimerComponent from "../custom/TimerComponent"
import Style from "./popQuizResults.module.scss"

//import your fontawesome icons here
import Image from "../custom/Image"
import ChevronCircleDown from "../../images/svgs/ChevronCircleDown.svg"
import ChevronCircleUp from "../../images/svgs/ChevronCircleUp.svg"
import Clock from "../../images/svgs/clock.svg"
import User from "../../images/svgs/user.svg"

import { getRequest, putRequest } from "../../services/rest_service"
import Loader from "../custom/Loader"
import BulkEndAction from "../common/BulkEndAction"
import close from "../../images/svgs/close.svg"
import AvatarCircle from "../custom/AvatarCircle"

const Answer = ({ data, resourceId }) => {
  const [studentList, setStudentList] = useState([])
  const [loader, setLoader] = useState(false)
  const fetchStudentList = async () => {
    setLoader(true)
    const response = await getRequest(`/quiz-option-snapshot/${data.id}/answered_students/?resource=${resourceId}`)
    if (response.success) {
      setStudentList(response.data)
      setLoader(false)
    }
  }
  let background = ""
  if (data.answer_percentage.percentage > 0) {
    if (parseInt(data.answer_percentage.percentage) < 50) {
      background = `linear-gradient(to left, #42506C ${`${100 - parseInt(data.answer_percentage.percentage)}%`}, #5D6D8D ${`${
        100 - parseInt(data.answer_percentage.percentage)
      }%`})`
    } else {
      background = `linear-gradient(to right, #5D6D8D ${`${parseInt(data.answer_percentage.percentage)}%`}, #42506C ${`${parseInt(
        data.answer_percentage.percentage
      )}%`})`
    }
  } else {
    background = "#42506C"
  }
  return (
    <div className="w-100" style={{ fontSize: "14px" }}>
      <div
        className={`w-100 d-inline-flex mb-2  ${data?.is_correct ? Style.green_border : ""}`}
        style={{
          borderRadius: "3px",
          background: `${background}`,
        }}
      >
        <div
          className={`mb-0 pl-3 flex-grow-1 py-2 poppins_font w-100`}
          style={{
            fontSize: "16px",
            fontWeight: "500",
            left: "0px",
            top: "20%",
          }}
        >
          {data.name}
        </div>
        <div
          className={`px-2 d-flex`}
          style={{
            minWidth: "120px",
            borderRadius: "3px",
          }}
        >
          {data.answer_percentage.count > 0 ? (
            <span className={` underline pointer my-auto`}>
              <Dropdown
                onClick={(e) => {
                  e.preventDefault()
                  fetchStudentList()
                }}
                className={`breakout-groups mr-0`}
              >
                <Dropdown.Toggle
                  className="border-none d-inline-flex align-items-center p-0 shadow-none"
                  style={{ backgroundColor: "transparent" }}
                  id="dropdown-basic"
                >
                  <u className="mb-0 pointer" style={{ fontSize: "16px", fontWeight: "500" }}>{`${parseInt(data.answer_percentage.count)} ${
                    data.answer_percentage.count === 1 ? "Participant" : `Participants`
                  }`}</u>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`br-9px breakout-groups ${Style.min_fit_width}`} style={{ fontSize: "14px", color: "#E0E2E5" }}>
                  {loader && (
                    <Dropdown.Item className={`py-1`} style={{ color: "#E0E2E5" }}>
                      <Loader />
                    </Dropdown.Item>
                  )}
                  {studentList?.map((student, index) => {
                    return (
                      <Dropdown.Item key={`student_${index}`} className={`py-1`} style={{ color: "#E0E2E5" }}>
                        <div className="d-flex">
                          <AvatarCircle index={index} size={"20px"} name={student?.owner?.first_name} avatar={student?.owner?.profile_pic} />
                          <div className="ml-1">{student?.owner?.first_name}</div>
                        </div>
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </span>
          ) : (
            <span className={`my-auto`} style={{ fontSize: "16px", fontWeight: "500" }}>{`${parseInt(data.answer_percentage.count)} Participant`}</span>
          )}
        </div>
      </div>
    </div>
  )
}

const StudentCount = (props) => {
  let progress = (props?.current / props?.total) * 100
  return (
    <div className={`d-flex ${props?.className ? props?.className : ""}`} style={{ fontSize: "14px", height: "1em" }}>
      <Image src={User} className={`mt-1`} style={{ height: 15 }} />
      <div className={`d-flex flex-column ml-1`}>
        <div>
          {props?.current}/{props?.total} students
        </div>
        <div className={`${Style.student_count_wrap}`}>
          <div style={{ width: progress + "%", backgroundColor: "white", height: "5px", borderRadius: "2px" }}></div>
        </div>
      </div>
    </div>
  )
}

const Question = ({ data, totalStudents, index, resourceId }) => {
  const [expand, setExpand] = useState(true)
  return (
    <div className={`d-flex mb-2`}>
      <div className={`pr-2 font-weight-bolder`} style={{ fontSize: "26px" }}>
        {index}
      </div>
      <div className={`flex-fill d-flex flex-column ${Style.ques_box}`}>
        <div className="px-2 py-1 d-flex justify-content-between">
          <div style={{ fontSize: "16px" }}>{data?.title}</div>
          <div style={{ height: "1em" }}>
            <Image
              src={expand ? ChevronCircleUp : ChevronCircleDown}
              className={`pointer`}
              onClick={(e) => {
                setExpand((prev) => !prev)
              }}
              style={{ height: 20 }}
            />
          </div>
        </div>
        {expand && (
          <div style={{ borderTop: "1px solid #42506C" }} className="px-2 py-1">
            {data?.options?.map((ans, index) => {
              return <Answer data={ans} key={index + "ans_ques"} resourceId={resourceId} />
            })}
            <StudentCount current={data?.answered_student_count} total={totalStudents} className={`my-2`} />
          </div>
        )}
      </div>
    </div>
  )
}

const PopQuizResults = (props) => {
  const [quizData, setQuizData] = useState([])
  const [studentsCount, setStudentsCount] = useState({ current: 0, total: 0 })
  const [loader, setLoader] = useState(false)
  const [duration, setDuration] = useState(0)
  const [isSocketReady, recvSocketData, sendSocket] = useContext(TrainingSocketContext)
  const [classroomStore, setClassroomStore] = useContext(ClassroomContext)
  const [globalStore, globalDispatch] = useContext(GlobalContext)
  const [lessonboardStore, lessonboardDispatch] = useContext(LessonboardContext)
  const resource = props?.resource
  const [showResults, setShowResults] = useState(resource?.show_results)
  const [isEnd, setIsEnd] = useState(resource?.end_activity)
  const [showPublishResultsAgainBtn, setShowPublishResultsAgainBtn] = useState(1)
  const [popQuizDetail, setPopQuizDetail] = useState(null)

  useEffect(() => {
    if (resource?.id) {
      loadData()
    }
  }, [resource])

  useEffect(() => {
    if (popQuizDetail?.quiz_time) {
      let start_time = new Date(resource?.triggered_time)
      let duration = popQuizDetail?.quiz_time
      duration = duration.split(".")
      duration = parseInt(duration[0]) * 60 + parseInt(duration[1])
      setDuration(duration / 60)
      start_time.setSeconds(start_time.getSeconds() + duration)
      let cur_time = new Date()
      if (cur_time > start_time) {
        setIsEnd(true)
      }
    }
  }, [popQuizDetail?.quiz_time])

  useEffect(() => {
    if (isSocketReady && recvSocketData && Object.keys(recvSocketData).length > 0) {
      if (parseInt(recvSocketData.triggered_resource_report_count) === parseInt(resource?.triggered_count) && recvSocketData.resource_id === resource?.id) {
        fetchResultData()
      }
    }
  }, [isSocketReady, recvSocketData])

  const getPopQuizDetails = async () => {
    if (resource?.version_id <= 0) return
    const response = await getRequest(`/quiz/${resource?.version_id}/quiz_version_data/?resource=${resource?.id}`)
    if (response.success) {
      setPopQuizDetail(response.data)
    }
  }

  const loadData = async () => {
    setLoader(true)
    await getPopQuizDetails()
    await fetchResultData()
    setLoader(false)
    setIsEnd(resource?.end_activity)
  }

  const fetchResultData = async () => {
    const response = await getRequest(`/quiz-report-card/${resource.id}/poll_trainer_results/?training=${classroomStore.training_id}`)
    if (response.success) {
      setQuizData(response?.data?.questions)
      setStudentsCount({ current: response?.data?.attending_students, total: response?.data?.total_participants })
    }
  }

  const popQuizStatusUpdate = async (resource, end_activity, show_results = false) => {
    let formData = new FormData()
    formData.append("sequence", resource?.sequence)
    formData.append("resource_id", resource?.resource_id)
    if (end_activity) {
      formData.append("end_activity", end_activity)
      formData.append("show_results", false)
    }
    formData.append("resource_type", resource?.resource_type)
    if (show_results) {
      formData.append("end_activity", true)
      formData.append("show_results", show_results)
    }
    formData.append("is_triggered", true)
    const response = await putRequest(`/resources/${resource?.id}/`, formData)
    if (response.success) {
      let topicId = `topic_${resource?.topic}`,
        topicResources = { ...lessonboardStore.resources }
      let index = topicResources[topicId]?.resources?.findIndex((res) => res.id === resource.id)
      if (index > -1) {
        topicResources[topicId].resources[index] = response.data
        let payload = { key: topicId, value: { resources: topicResources[topicId].resources, resourceNext: topicResources[topicId].resourceNext } }
        lessonboardDispatch({ type: SET_RESOURCE_DATA, payload })
      }
      setIsEnd(response?.data?.end_activity)
      setShowResults(response?.data?.show_results)
    }
  }

  const triggerResource = async (resource) => {
    setTimeout(async function () {
      let topic_data = "",
        topicId = resource?.topic
      if (topicId != null) {
        topic_data = "topic=" + topicId + "&resource=" + resource.id
      }
      await getRequest(`/basic-resources-list/?paginate_by=1&is_triggered=true&is_poll=false&template_id=${classroomStore.template_id}&${topic_data}`)
    }, 300)
  }

  const handleEndPopQuiz = () => {
    fetchResultData()
    popQuizStatusUpdate(resource, true)
  }

  function endForAll() {
    if (document.getElementById("bulk-end-btn")) {
      document.getElementById("bulk-end-btn").click()
    }
  }

  const handlePublishResult = () => {
    popQuizStatusUpdate(resource, false, true)
    triggerResource(resource)
    setShowPublishResultsAgainBtn(2)
  }

  function handleHidePopQuizModal() {
    setClassroomStore({ type: SHOW_TRIGGERED_RESOURCE, payload: false })
    if (showResults && showPublishResultsAgainBtn === 2) {
      sendSocket && sendSocket(JSON.stringify({ event_type: "close_triggered_resource", page: "none" }))
      setClassroomStore({ type: SET_ACTIVE_ACTIVITY_DATA, payload: {} })
    }
  }

  return (
    <Modal
      show={true}
      onHide={handleHidePopQuizModal}
      backdrop={`static`}
      centered={true}
      size="lg"
      aria-labelledby="example-custom-modal-styling-title"
      className="classroom-poll-modal"
      style={{ paddingTop: "2rem", border: "none", color: "#E0E2E5" }}
    >
      <Modal.Body
        style={{
          borderRadius: "8px",
          backgroundColor: "#212C42",
          padding: "0",
          border: isEnd ?? resource?.end_activity ? "1px solid #929BBD" : "2px solid #1CAAAA",
          boxShadow: "0px 3px 12px #00000099",
          overflowY: "auto",
        }}
      >
        <div>
          <div className="d-flex justify-content-between mb-2 align-items-center" style={{ padding: "8px 12px", borderBottom: "1px solid #929bbd" }}>
            <div className="d-flex" style={{ lineHeight: 1, margin: "0px" }}>
              <div>
                {resource?.resource_data?.name} {isEnd ? "Result" : "Live"}
              </div>
              <div
                className="mx-1 circle align-self-center"
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: isEnd ? "#212C42" : "#1CAAAA",
                }}
              />
            </div>
            <div className={`d-flex align-items-center`}>
              {popQuizDetail?.quiz_time !== null && !isEnd && (
                <div className={`mr-2 pt-1`}>
                  <Image src={Clock} className={`mr-1`} style={{ height: 15 }} />
                  <span className="fs-14px">
                    <TimerComponent
                      totalTimerDuration={duration}
                      startedTime={new Date(resource?.triggered_time)}
                      onTimerAction={(isEnded) => {
                        if (isEnded) {
                          endForAll()
                        }
                      }}
                      timerType="decrement"
                    />
                  </span>
                </div>
              )}
              <StudentCount current={studentsCount.current} total={studentsCount.total} />
              <div className={`ml-2`}>
                <img
                  className="pointer m-0"
                  onClick={(e) => {
                    let data = { ...resource }
                    if (isEnd === true || isEnd === false) {
                      data.end_activity = isEnd
                    }
                    if (showResults === true || showResults === false) {
                      data.show_results = showResults
                    }
                    if (showResults === true || isEnd === true) {
                      const evt = new CustomEvent("my-pop-quiz-results", {
                        bubbles: true,
                        detail: data,
                      })
                      document.dispatchEvent(evt)
                    }
                    handleHidePopQuizModal()
                  }}
                  src={close}
                  style={{ width: `15px`, height: `15px` }}
                />
              </div>
            </div>
          </div>
          <div className={`p-2`} style={{ maxHeight: "550px", overflowY: "auto", minHeight: "100px" }}>
            {loader && (
              <div className={`w-100 text-center`}>
                <Loader />
              </div>
            )}
            {quizData?.map((ques, index) => {
              return <Question index={index + 1} totalStudents={studentsCount.total} resourceId={resource?.id} data={ques} key={`ques_quiz_${index}`} />
            })}
          </div>
          {(!isEnd || !showResults) && (
            <div className={`w-100`}>
              <div
                className={`pointer d-flex justify-content-around px-0 w-100`}
                style={{
                  backgroundColor: "#586886",
                }}
              >
                <div className={`w-100 py-1 text-center`}>
                  {!showResults && isEnd ? (
                    <div onClick={handlePublishResult}>Publish Result</div>
                  ) : (
                    <BulkEndAction
                      success_message="Quiz Ended Successfully"
                      handleRefresh={handleEndPopQuiz}
                      title="End Test For All"
                      triggeredCount={parseInt(resource?.triggered_count)}
                      resID={resource?.id}
                      action="end_test"
                      training_id={parseInt(classroomStore.training_id)}
                      sendSocket={sendSocket}
                      close={() => {
                        setClassroomStore({ type: VIEW_MODERATION_RESOURCE, payload: classroomStore.activeActivityData?.current_active_activity })
                        setClassroomStore({ type: SET_ACTIVE_ACTIVITY_DATA, payload: {} })
                      }}
                      type="text"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PopQuizResults
