import React, { useEffect, useState, useCallback, useContext } from "react"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"
import Modal from "react-bootstrap/Modal"

import { TrainingSocketContext } from "../../stores/socket/training_socket/trainingSocketProvider"
import { DisplayQuestionAnswers } from "./moderation-components"
import { getRequest, putRequest } from "../../services/rest_service"

import Btn from "../custom/Button"
import Loader from "../custom/Loader"
import Image from "../custom/Image"

import clock from "../../images/svgs/clock.svg"
import Close from "../../images/svgs/close.svg"
import CheckWhite from "../../images/svgs/check-white.svg"

import csStyles from "./casestudy.module.scss"
import globalStyles from "../../modularscss/styles.module.scss"

const CaseStudyReportCard = (props) => {
  const [errorMsg, setErrorMsg] = useState("")
  const [masterData, setMasterData] = useState({})
  const [classAvg, setClassAvg] = useState({})
  const [qaDetails, setQaDetails] = useState([])
  const [dispFudgePtSuccess, setDispFudgePtSuccess] = useState(false)
  const [published, setPublished] = useState(null)
  const [loader, setLoader] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isSocketReady, recvSocketData, sendSocket] = useContext(TrainingSocketContext)

  const delayedQuery = useCallback(
    debounce((name, value, func) => handleSubmitData(name, value, func), 1000),
    []
  )

  const handleSubmitData = async (name, value, func) => {
    if (!props?.studentReportCardId) return
    var formData = { resource: props.resourceId, [name]: value }
    setLoader(true)
    let res = await putRequest(`/case-study-student-report-card/${props?.studentReportCardId}/`, formData)
    if (res.success) {
      setMasterData((prev) => {
        return { ...prev, ...res.data }
      })

      func && func()
    } else {
      props?.onError && props.onError(res.msg)
    }
    setLoader(false)
  }

  useEffect(() => {
    if (props?.studentReportCardId > 0) {
      getStudentReportCardData(`/case-study-student-report-card/${props?.studentReportCardId}/report_card_data/?limit=25`, false, true)
    }
  }, [props?.studentReportCardId])

  useEffect(() => {
    if (
      isSocketReady &&
      recvSocketData &&
      Object.keys(recvSocketData).length > 0 &&
      recvSocketData.event_type === "fetch_leader_board" &&
      recvSocketData.source === "individual_case_study"
    ) {
      if (parseInt(recvSocketData.report_card_id) === props?.studentReportCardId) {
        getStudentReportCardData(`/case-study-student-report-card/${props?.studentReportCardId}/report_card_data/?limit=25`, false, true)
      }
    }
  }, [isSocketReady, recvSocketData])

  const getStudentReportCardData = async (url, nextType = false, showLoading = false) => {
    !nextType && showLoading && setShowLoader(true)

    let res = await getRequest(url)
    if (res.success) {
      let s = res.data
      if (nextType) {
        setMasterData((prev) => {
          return { ...prev, ...s.report_track }
        })
      } else {
        setMasterData(s.report_track)
      }

      if (s.next !== null) {
        await getStudentReportCardData(s.next, true)
      }
      setClassAvg(s.class_average)
      setQaDetails(s.results)
      //setStudentData(s?.owner)
    } else {
      props?.onError && props.onError(res.msg)
    }
    setShowLoader(false)
  }

  const updateMarks = (value, index) => {
    let dummy = [...qaDetails]
    dummy[index].answer_map.marks_gained = value
    setErrorMsg("")
    setQaDetails(dummy)
  }

  const handleUpdateMarks = async (index, data, value) => {
    if (props?.studentReportCardId && data.answer_map.id) {
      setLoader(true)
      var formData = {
        resource: props.resourceId,
        question: data.id,
        case_study_student_report_card: props?.studentReportCardId,
        marks_gained: value,
      }
      let res = await putRequest(`/case-study-student-answer-submit/${data.answer_map.id}/`, formData)
      if (res.success) {
      } else {
        props?.onError && props.onError(res.msg)
      }
      setLoader(false)
    }
  }

  const updateQuestionMarks = async (index, data, value) => {
    if (data.answer_map.id) {
      value = value ? Number(value) : value
      if (value < 0) {
        setErrorMsg("Please give valid marks")
      } else if (value > data.answer_map.total_marks) {
        setErrorMsg(`Please give marks less than or equal to ${data.answer_map.total_marks}`)
      } else {
        updateMarks(value, index)
        value !== "" && value >= 0 && delayedUpdateQuestionMarks(index, data, value)
      }
    }
  }

  const delayedUpdateQuestionMarks = useCallback(
    debounce((index, data, value) => {
      handleUpdateMarks(index, data, value)
    }, 1000),
    []
  )

  function getTimetaken(time) {
    let sec = time * 60
    let sec_round = Math.round(sec)
    let mins = parseInt(sec_round / 60)
    let secs = parseInt(sec_round % 60)
    let text = mins > 1 ? `mins` : `min`
    let res = `${mins} ${text} ${secs} sec`
    // let res = `${mins}:${secs}mins`
    return res
  }

  const displayGradingData = () => {
    return (
      <div className={`ml-4 mr-2 d-flex flex-row`}>
        {/**Total Attempts */}
        <div className={`d-flex flex-column align-items-center mr-3 py-2 ${csStyles.grading_header_container}`}>
          <div className={`p-1 ${csStyles.grading_header}`}>Total Attempts</div>
          <div className={`d-flex ${csStyles.grading_data}`}>{masterData.total_attempts}</div>
          {classAvg !== null ? (
            <div className={`${csStyles.grading_data_end}`}>Class average = {classAvg?.avg_attempts !== 0 ? classAvg.avg_attempts : "--"}</div>
          ) : null}
        </div>
        {/**Results */}
        <div className={`d-flex flex-column align-items-center mr-3 py-2 ${csStyles.grading_header_container}`}>
          <div className={`p-1 ${csStyles.grading_header}`}>Results</div>
          <div className={`d-flex ${csStyles.grading_data}`}>{results === "Fail" ? <span className={`text-danger`}>{results}</span> : results}</div>
          {classAvg !== null ? (
            <div className={`${csStyles.grading_data_end}`}>
              Class Success Rate = {classAvg?.is_class_pass_percentage !== 0 ? `${classAvg.is_class_pass_percentage}%` : "--"}
            </div>
          ) : null}
        </div>
        {/**Final Score */}
        <div className={`d-flex flex-column align-items-center mr-3 py-2 ${csStyles.grading_header_container}`}>
          <div className={`p-1 ${csStyles.grading_header}`}>{masterData.case_study_status === "2" ? "Final" : "Current"} Score</div>
          <div className={`d-flex ${csStyles.grading_data}`}>
            {total}/{masterData.total_marks}
          </div>
          {classAvg !== null ? (
            <div className={`${csStyles.grading_data_end}`}>
              Class average = {classAvg?.avg_marks_percent !== 0 ? `${classAvg.avg_marks} (${classAvg.avg_marks_percent}%)` : "--"}
            </div>
          ) : null}
        </div>
        {/**Time Taken*/}
        <div className={`d-flex flex-column align-items-center mr-3 py-2 ${csStyles.grading_header_container}`}>
          <div className={`p-1 ${csStyles.grading_header}`}>Time Taken</div>
          <div className={`d-flex ${csStyles.grading_data}`}>{masterData.case_study_status === "2" ? getTimetaken(masterData.time_taken) : "--"}</div>
          {classAvg !== null ? (
            <div className={`${csStyles.grading_data_end}`}>
              Class average ={classAvg?.time_taken !== 0 || masterData.case_study_status === "2" ? getTimetaken(classAvg.time_taken) : "--"}
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  const handlePublishResults = async () => {
    setPublished(false)
    let res = await getRequest(`/case-study-student-answer-submit/calculatescore/?report_card=${props.studentReportCardId}&publish_report_card=true`)
    if (res.success) {
      setPublished(true)
    } else {
      props?.onError && props.onError(res.msg)
    }
  }

  /*May need in future
  const handleAnotherAttempt = async () => {
    let res = await getRequest(`/case-study-student-answer-submit/reattempt/?learner_id=${props.id}`)
    if (res.success) {
      props.onHide()
      props.onSuccess()
    } else {
      props?.onError && props.onError(res.msg)
    }
  }*/

  const displayRemarks = () => {
    return (
      <div className={`mx-4 px-2 border_on_hover pb-2 ${csStyles.remarks_container}`}>
        <label htmlFor="remark" className={`${csStyles.label}`}>
          Remarks
        </label>
        <textarea
          className={`border-0 d-flex pt-0 w-100 justify-content-center shadow-none text-white ${csStyles.textarea}`}
          name="remark"
          value={masterData.remark}
          onChange={(e) => {
            setMasterData({ ...masterData, remark: e.target.value })
            delayedQuery("remark", e.target.value)
          }}
          id="remark"
          placeholder=""
        />
      </div>
    )
  }

  const displayFudgePoints = () => {
    if (results === "Fail" || masterData.fudge_points !== 0) {
      return (
        <div className={`px-4`}>
          <div className={`d-flex flex-row p-2 ${csStyles.fudge_points_container}`}>
            <label className={`${csStyles.label}`} htmlFor={`fudgePoint`}>
              {results !== "Passed" ? "Add Fudge Points:" : "Fudge Points given:"}
            </label>
            <div>
              <input
                className={`border-0 mx-1 p-1 px-2 text-white shadow-none ${csStyles.fudge_points}`}
                id="fudgePoint"
                type="number"
                name="fudge_points"
                readOnly={results === "Passed"}
                maxLength={"3"}
                defaultValue={masterData.fudge_points !== 0 ? masterData.fudge_points : ""}
                onChange={(e) => {
                  delayedQuery(e.target.name, e.target.value, () => {
                    setDispFudgePtSuccess(true)
                    setTimeout(() => {
                      setDispFudgePtSuccess(false)
                    }, 1000)
                  })
                }}
                placeholder=""
              />
            </div>
            <div className={`border-0 d-flex flex-grow-1 pb-0 justify-content-end ${csStyles.fudge_points_text}`}>
              Final Score = Original score + Fudge points
            </div>
          </div>
          {dispFudgePtSuccess ? (
            <div className={`d-flex flex-row border border-white rounded p-2 ${csStyles.fudge_success}`}>
              <div className={`pl-1 pr-4`}>
                <Image src={clock} className={csStyles.timeline_img} />
              </div>
              <div>
                The Final score will be {total} ({results})
              </div>
            </div>
          ) : null}
        </div>
      )
    } else return null
  }

  const publishResults = () => {
    return (
      <div className={`mx-4 py-2 d-flex flex-row`}>
        <div className={`d-flex`}>
          {masterData.case_study_status === "2" ? (
            <div>
              {published === null || published === false ? (
                <Btn onClick={(e) => handlePublishResults()} disabled={published === false ? true : false}>
                  {published === false && (
                    <>
                      <Loader />
                      <span>Publishing...</span>
                    </>
                  )}
                  {published === null && "Publish Results"}
                </Btn>
              ) : (
                <Btn onClick={(e) => props.onHide()}>Done</Btn>
              )}
              {/*May need in future
                &nbsp;
                <Btn onClick={(e) => handleAnotherAttempt()} className={`border_on_hover fs-14px mt-2 ml-4 px-3 ${csStyles.give_chance}`}>
                  Give Another Chance
                </Btn>*/}
            </div>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        <div className={`d-flex ml-auto`}>
          {loader ? (
            <div className={`${csStyles.waiting_text}`}>
              <Loader class={``} />
              &nbsp; Changes being saved...
            </div>
          ) : (
            <div className={`${csStyles.waiting_text}`}>
              <Image className={`ml-3 ${csStyles.img_loader}`} src={CheckWhite} />
              &nbsp; All changes are saved
            </div>
          )}
        </div>
      </div>
    )
  }

  const total = parseFloat(masterData.marks_gained) + parseFloat(masterData.fudge_points),
    totalPercentage = (total / parseFloat(masterData.total_marks)) * 100,
    results = masterData.case_study_status == 2 ? (totalPercentage >= parseFloat(masterData.passing_marks) ? "Passed" : "Fail") : "On going"

  return (
    <Modal
      size="xl"
      show={props?.show}
      onHide={() => props.onHide()}
      aria-label="Modal - Case Study Student's Report Card View"
      centered
      dialogClassName={`${csStyles.wh80}`}
      scrollable={true}
      keyboard={false}
      backdrop={"static"}
    >
      <Modal.Header className={`p-3 pb-1 flex-column border-bottom-0 ${csStyles.cs_modal}`}>
        <div className={`d-flex w-100 justify-content-between`}>
          <div className="my-auto mb-2 d-inline-flex mr-2 ml-2 align-self-center flex-shrink-0">
            <b>Case Study Report Card : {props.studentName}</b>
          </div>
          <div>
            <Image src={Close} onClick={() => props.onHide()} className={`pointer align-items-center ${globalStyles.close_img_container}`} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className={`p-1 ${csStyles.cs_modal}`}>
        {/*showLoader ? (
          <div className="d-flex mx-auto">
            <Loader class={`my-auto mx-auto mr-1 ${csStyles.img_loader}`} />
          </div>
        ) : (*/}
        <div className={`m-0 px-3`}>
          {/**Main Heading */}
          {props?.caseStudyVersionData.grading && displayGradingData()}

          {/**Questions & Answers */}
          {qaDetails.map((questionAnswer, idx) => (
            <React.Fragment key={`qa_${idx}`}>
              <DisplayQuestionAnswers
                type="student-view"
                index={idx}
                studentName={props.studentName}
                studentProfilePic={props.studentProfilePic}
                questionAnswer={questionAnswer}
                caseStudy={props?.caseStudyVersionData}
                errorMsg={errorMsg}
                updateQuestionMarks={updateQuestionMarks}
                published={published}
              />
            </React.Fragment>
          ))}
          {displayRemarks()}
          {props?.caseStudyVersionData.grading ? displayFudgePoints() : null}
          {publishResults()}
        </div>
        {/*)}*/}
      </Modal.Body>
    </Modal>
  )
}

CaseStudyReportCard.propTypes = {
  resourceId: PropTypes.number.isRequired, //Resource ID
  id: PropTypes.number.isRequired, //ID of Case Study of the Student
  studentReportCardId: PropTypes.number.isRequired, //Report Card ID of the Student
  studentName: PropTypes.string.isRequired, //Student Name
  studentProfilePic: PropTypes.string, //Student Profile Pic
  caseStudyVersionData: PropTypes.object.isRequired, //Version of Case Study revision data
  show: PropTypes.bool.isRequired, //To show the modal
  onHide: PropTypes.func.isRequired, //To Hide the Modal
  //onSuccess: PropTypes.func.isRequired, //On Success Make Another Attempt Call Back
  onError: PropTypes.func.isRequired, //On Error of Backend API call back
}
export default CaseStudyReportCard
