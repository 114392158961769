import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Modal from "react-bootstrap/Modal"
import { getRequest } from "../../services/rest_service"
import Image from "../custom/Image"
import Loader from "../custom/Loader"

import { DisplayQuestionAnswers } from "./moderation-components"

import Link from "../../images/svgs/classroom/link.svg"
import Close from "../../images/svgs/close.svg"

import csStyles from "./casestudy.module.scss"
import globalStyles from "../../modularscss/styles.module.scss"

const CaseStudyView = (props) => {
  const [caseStudy, setCaseStudy] = useState({})
  const [qaDetails, setQaDetails] = useState([])
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    if (props.caseStudyVersionID && props.caseStudyVersionID > 0) {
      getData()
    }
  }, [props.caseStudyVersionID])

  const getData = async () => {
    setShowLoader(true)
    await getReportCardVersionMainData()
    await getReportCardVersionQAData(`/case-study-student-report-card/${props.caseStudyVersionID}/case_study_version_questions_listing/?limit=25`)
    setShowLoader(false)
  }

  const getReportCardVersionQAData = async (url, nextType = false) => {
    let res = await getRequest(url)
    if (res.success) {
      if (nextType) {
        setQaDetails((arr) => [...arr, res.data.results])
      } else {
        setQaDetails(res.data.results)
      }
      if (res.data.next !== null) {
        await getReportCardVersionQAData(res.data.next, true)
      }
    } else {
      props?.onError && props.onError(res.msg)
    }
  }

  const getReportCardVersionMainData = async () => {
    if (props.caseStudyVersionID <= 0) return
    let res = await getRequest(`/case-study-student-report-card/${props.caseStudyVersionID}/case_study_version_data/`)
    if (res.success) {
      setCaseStudy(res.data)
    } else {
      props?.onError && props.onError(res.msg)
    }
  }

  const dispMainData = (obj) => {
    return (
      <>
        <div className={`mx-3`}>
          <div className={`p-2 m-2 ${csStyles.view_gray_background_row}`}>
            <span className={`${csStyles.view_name}`}>Name:&emsp;</span>
            <span className={`font-weight-bold`}>{obj?.name || "--"}</span>
          </div>
          <div className={`p-2 m-2 ${csStyles.view_gray_background_row_big}`}>
            <div className={`${csStyles.view_content}`}>
              <p className={`${csStyles.view_name}`}>Case Study Content</p>
              <p dangerouslySetInnerHTML={{ __html: obj?.content || "--" }} />
            </div>
            {obj?.links_json?.length > 0 ? (
              <div className={`m-1`}>
                <p className={`${csStyles.view_name}`}>External Links</p>
                <div>
                  {obj.links_json.map((linkObj, idx) => (
                    <div key={`links_json_${idx}`} className={`d-flex align-items-center pb-1 pt-2 px-3 mt-2 ${csStyles.view_resource_background_row}`}>
                      <Image className={`m-0 ${csStyles.view_toggle}`} src={Link} />
                      <span className={`ml-2`}>{linkObj.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {obj?.resources_json?.length > 0 ? (
              <div className={`m-2 mt-3`}>
                <p className={`${csStyles.view_name}`}>Resources</p>
                <div>
                  {obj.resources_json.map((resObj, idx) => (
                    <div key={`resource_json_${idx}`} className={`pb-1 pt-2 px-3 mt-2 ${csStyles.view_resource_background_row}`}>
                      {resObj.resource_name}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Modal
        size="xl"
        show={props?.show}
        onHide={() => props.hide()}
        aria-label="Modal - Case Study View"
        centered
        dialogClassName={`${csStyles.wh80}`}
        scrollable={true}
      >
        <Modal.Header className={`p-3 pb-1 flex-column border-bottom-0 ${csStyles.cs_modal}`}>
          <div className={`d-flex w-100 justify-content-between`}>
            <div className="my-auto mb-2 d-inline-flex mr-2 align-self-center flex-shrink-0">
              <b>Case Study View</b>
            </div>
            <div>
              <Image src={Close} onClick={() => props.hide()} className={`pointer align-items-center ${globalStyles.close_img_container}`} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={`p-1 ${csStyles.cs_modal}`}>
          {showLoader ? (
            <div className="d-flex mx-auto">
              <Loader class={`my-auto mx-auto mr-1 ${csStyles.img_loader}`} />
            </div>
          ) : (
            <div className={`m-0 px-4`}>
              {dispMainData(caseStudy)}
              <div>
                {qaDetails.map((questionAnswer, idx) => (
                  <React.Fragment key={`qa_${idx}`}>
                    <DisplayQuestionAnswers type="case-study-view" index={idx} questionAnswer={questionAnswer} caseStudy={caseStudy} />
                  </React.Fragment>
                ))}
                <br />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
CaseStudyView.propTypes = {
  caseStudyVersionID: PropTypes.number.isRequired, //Version ID of Case Study
  show: PropTypes.bool.isRequired, //To show the modal
  hide: PropTypes.func.isRequired, // hiding the modal function
  onError: PropTypes.func.isRequired, //onError function
}

export default CaseStudyView
