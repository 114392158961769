import React, { useEffect, useState, useRef, useContext } from "react"
import PropTypes from "prop-types"
import Loadable from "@loadable/component"
import Col from "react-bootstrap/Col"
import Dropdown from "react-bootstrap/Dropdown"
import { TrainingSocketContext } from "../../stores/socket/training_socket/trainingSocketProvider"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import { SET_ACTIVE_ACTIVITY_DATA, VIEW_MODERATION_RESOURCE } from "../../stores/classroom_store/ClassroomActions"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"
import utcDate from "../../services/format_date"
import Image from "../custom/Image"
import Loader from "../custom/Loader"

import { getRequest } from "../../services/rest_service"
import AvatarCircle from "../custom/AvatarCircle"

import {
  DONT_SHOW,
  SHOW_EXTEND_TIME,
  SHOW_EXTEND_TIME_SUCCESS,
  SHOW_VIEW_DETAILS,
  SHOW_VIEW_STUDENT_DETAILS,
  SHOW_EXTEND_TIME_CONFIRM,
} from "./moderation-constants"

import clock from "../../images/svgs/clock.svg"
import timeline from "../../images/svgs/timeline.svg"
import ChevronDown from "../../images/svgs/down_arrow_white.svg"

import csStyles from "./casestudy.module.scss"
import turient from "../../modularscss/styles.module.scss"

import { AcceptExtendTime, ExtendedTimeSuccess, CaseStudyDetails } from "./moderation-components"
const BulkEndAction = Loadable(() => import("../common/BulkEndAction"))
const ReportCard = Loadable(() => import("../case-study/report-card"))
const ViewDetails = Loadable(() => import("../case-study/view"))

const CaseStudyModeration = (props) => {
  const [studentsListingLoading, setStudentsListingLoading] = useState(false)
  const [caseStudy, setCaseStudy] = useState({ name: "Case Study", time: 0 })
  const [studentsCSList, setStudentsCSList] = useState([])
  const [propsForComponent, setPropsForComponent] = useState({})
  const [action, setAction] = useState({ show: DONT_SHOW, id: 0 })
  const [isSocketReady, recvSocketData, sendSocket] = useContext(TrainingSocketContext)
  const [classroomStore, setClassroomStore] = useContext(ClassroomContext)
  const [globalStore, globalDispatch] = useContext(GlobalContext)
  const [inputExtTime, setInputExtTime] = useState(0)

  const [startIdx, setStartIdx] = useState(-1)
  const [endIdx, setEndIdx] = useState(-1)
  const [rankBySort, setRankBySort] = useState("normal")

  const IntervalPerSID = useRef(null)

  const resource = props?.resource ?? {}
  const resID = resource?.id
  const trainingID = classroomStore?.training_id
  const triggeredCount = parseInt(resource?.triggered_count)
  const caseStudyVID = resource?.version_id

  const closeInterval = (interval) => {
    if (interval?.current != null) {
      clearInterval(interval.current)
      interval.current = null
    }
  }
  useEffect(() => {
    getStudentsList()
  }, [rankBySort])
  useEffect(() => {
    getCaseStudyDetails()
  }, [props?.resource?.id])

  useEffect(() => {
    updateStudentsTimeLeft()
    return () => {
      closeInterval(IntervalPerSID)
    }
  }, [studentsListingLoading])

  useEffect(() => {
    if (
      isSocketReady &&
      recvSocketData &&
      Object.keys(recvSocketData).length > 0 &&
      recvSocketData.event_type === "fetch_leader_board" &&
      recvSocketData.source === "individual_case_study"
    ) {
      if (parseInt(recvSocketData.triggered_resource_report_count) === triggeredCount && recvSocketData.resource_id === resID) {
        getStudentsList(true)
      }
    }
  }, [isSocketReady, recvSocketData])

  useEffect(() => {
    if (action.show === SHOW_EXTEND_TIME_CONFIRM) {
      handleExtendTimeForStudent(action.id)
    }
  }, [action])

  const getStudentsList = async (refetch = false) => {
    if (resID && resID <= 0) return
    if (trainingID && trainingID <= 0) return
    if (triggeredCount && triggeredCount <= 0) return
    if (!refetch) {
      setStudentsListingLoading(true)
    }
    const res = await getRequest(
      `/case-study-student-report-card/${resID}/students_list/?training=${trainingID}&triggered_count=${triggeredCount}&ordering=${rankBySort}`
    )
    if (res.success) {
      let s = res.data
      setStartIdx(s.start_index)
      setEndIdx(s.end_index)
      setStudentsCSList(s.results)
      if (!refetch) {
        setStudentsListingLoading(false)
      }
    } else {
      globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  function updateStudentsTimeLeft() {
    if (studentsListingLoading) return
    closeInterval(IntervalPerSID)
    IntervalPerSID.current = setInterval(() => {
      getStudentsList(true)
    }, 60000) // Run Every min
  }

  function calcTimeLeftPerStudent(studentReportCardTimeLeft) {
    if (studentReportCardTimeLeft) {
      let startDt = utcDate.utcDateTime(studentReportCardTimeLeft.start_time),
        currentDateTime = Date.now(),
        totalTime = studentReportCardTimeLeft.duration + studentReportCardTimeLeft.extend_time - (currentDateTime - startDt.getTime()) / 60000
      return totalTime < 0 ? -1 : totalTime
    }
    return 0
  }

  const getCaseStudyDetails = async () => {
    if (caseStudyVID && caseStudyVID <= 0) return
    const res = await getRequest(`/case-study-student-report-card/${caseStudyVID}/case_study_version_data/`)
    if (res.success) {
      setCaseStudy(res.data)
    } else {
      globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  const viewCaseStudyVersionDetails = () => {
    setAction({ show: SHOW_VIEW_DETAILS, id: 0 })
    setPropsForComponent({ caseStudyVersionID: caseStudyVID })
  }

  const handleViewStudentDetails = (id) => {
    let idx = studentsCSList.findIndex((o) => o.id == id)
    if (idx == -1) {
      return //This case should not happen
    }
    let reportCard = studentsCSList[idx]?.report_card
    setPropsForComponent({
      id: id,
      studentReportCardId: reportCard?.id ?? null,
      studentName: studentsCSList[idx]?.first_name ?? "",
      studentProfilePic: studentsCSList[idx]?.profile_pic ?? "",
    })
    setAction({ show: SHOW_VIEW_STUDENT_DETAILS, id: 0 })
  }

  const handleEndTest = async (id) => {
    let idx = studentsCSList.findIndex((o) => o.id == id)
    if (idx == -1) {
      return //This case should not happen
    }
    let res = await getRequest(
      `/case-study-student-answer-submit/calculatescore/?report_card=${studentsCSList[idx].report_card.id}&training_id=${trainingID}&learner_id=${studentsCSList[idx].id}&action=end_test&test_ended=true`
    )
    if (res.success) {
      // Submitted
      getStudentsList(true)
    }
  }

  const handlePauseTest = async (id) => {
    let idx = studentsCSList.findIndex((o) => o.id == id)
    if (idx == -1) {
      return //This case should not happen
    }

    let res = await getRequest(
      `/training-websocket-trigger/${trainingID}/action/?action=pause_test&learner_id=${id}&source=case_study&report_card_id=${studentsCSList[idx].report_card.id}`
    )
    if (res.success) {
      getStudentsList(true)
    }
  }
  const handleResumeTest = async (id) => {
    let idx = studentsCSList.findIndex((o) => o.id == id)
    if (idx == -1) {
      return //This case should not happen
    }

    const res = await getRequest(
      `/training-websocket-trigger/${trainingID}/action/?action=resume_test&learner_id=${id}&source=case_study&report_card_id=${studentsCSList[idx].report_card.id}`
    )
    if (res.success) {
      getStudentsList(true)
    }
  }

  const handleExtendTimeForStudent = async (id) => {
    if (inputExtTime <= 0) return
    let idx = studentsCSList.findIndex((o) => o.id == id)
    if (idx == -1) {
      return //This case should not happen
    }
    let res = await getRequest(
      `/training-websocket-trigger/${trainingID}/action/?action=extend_time&learner_id=${id}&source=case_study&minutes_extended=${inputExtTime}&report_card_id=${studentsCSList[idx].report_card.id}`
    )
    if (res.success) {
      getStudentsList(true)

      setAction({ show: SHOW_EXTEND_TIME_SUCCESS, id: id })
      setTimeout(() => {
        setAction({ show: DONT_SHOW, id: 0 })
      }, 5000)
    }
  }

  const studentsTableHeader = () => {
    let cssClasses
    if (caseStudy.grading === true) {
      cssClasses = [csStyles.col_a, csStyles.col_b, csStyles.col_c, csStyles.col_d, csStyles.col_f, csStyles.col_g]
    } else {
      cssClasses = [csStyles.col_a1, csStyles.col_b1, csStyles.col_d1, csStyles.col_e1, "", csStyles.col_g1]
    }
    return (
      <>
        <div key={`student_0`} className={`d-flex flex-row ${csStyles.trow} mb-2 font-weight-bold`}>
          <Col className={cssClasses[0]}>Name</Col>
          <Col className={cssClasses[1]}>Progress</Col>
          <Col className={cssClasses[2]}>Current Score</Col>
          <Col className={cssClasses[3]}>Time Left</Col>
          {caseStudy.grading ? (
            <Col
              className={cssClasses[4]}
              onClick={(e) => {
                e.preventDefault()
                setRankBySort(rankBySort === "normal" ? "reverse" : "normal")
              }}
            >
              Rank by: % Score &nbsp;
              <Image src={ChevronDown} className={`${rankBySort === "normal" ? csStyles.up_arrow : csStyles.down_arrow}`} />
            </Col>
          ) : null}
          <Col className={cssClasses[5]}>&nbsp;</Col>
        </div>
      </>
    )
  }

  const onStudentCaseStudyStatus = (studentReportCard) => {
    let reportStatus = studentReportCard?.case_study_status,
      reportStatusText
    /*CHOICES = (('0', 'Started'), ('1', 'Paused'), ('2', 'Submitted'), ('3', 'Not Started'))*/
    switch (reportStatus) {
      case undefined:
      case null:
      case "3":
        reportStatusText = ["Not Started", csStyles.report_status1]
        break
      case "0":
      case "1":
        reportStatusText = ["On-going", csStyles.report_status2]
        break
      case "2":
        reportStatusText = ["Submitted", csStyles.report_status3]
        break
      default:
        reportStatusText = []
        break
    }
    return reportStatusText
  }

  const getStudentRank = (index) => {
    let rank = 0
    if (startIdx <= endIdx) {
      if (startIdx <= index && index <= endIdx) {
        // && rankBySort === 'normal') {
        rank = index + 1 - startIdx
      } /*else if ( startIdx <= index && index <= endIdx && rankBySort === 'reverse') {
            rank = studentsCSList.length - endIdx - index;
      } */
    }
    return rank
  }

  const renderNameSection = (studentData, index) => {
    return (
      <>
        <div className={`d-flex flex-row`}>
          {[SHOW_EXTEND_TIME_CONFIRM, SHOW_EXTEND_TIME_SUCCESS].includes(action.show) && action.id === studentData.id ? (
            <span className={csStyles.clock_img_container}>
              <Image src={clock} className={csStyles.clock_img} />
            </span>
          ) : null}
          <AvatarCircle index={index} name={studentData?.first_name ?? "-"} avatar={studentData?.profile_pic} size={"30px"} />
          <div className={`d-flex flex-column px-2`}>
            <div>{studentData?.first_name ?? "--"}</div>
          </div>
        </div>
      </>
    )
  }

  const renderProgressSection = (studentReportCard) => {
    let reportStatusText = onStudentCaseStudyStatus(studentReportCard)
    return <>{reportStatusText.length > 0 ? <span className={`${turient.font_14} ${reportStatusText[1]}`}>{reportStatusText[0]}</span> : null}</>
  }

  const renderCurrentScore = (studentReportCard) => {
    let totalScore = studentReportCard?.total_score,
      avgScore = totalScore && totalScore > 0 ? ((studentReportCard?.score / totalScore) * 100)?.toFixed(2) + "%" : "--"

    return (
      <>
        <b>{studentReportCard === null ? "--" : `${studentReportCard.score}/${totalScore}`}</b>
        <span className={csStyles.fs_9px}>({avgScore})</span>
      </>
    )
  }

  const renderTimeLeftSection = (studentReportCard) => {
    let reportStatus = studentReportCard?.case_study_status,
      extTime = studentReportCard?.time_left?.extend_time ?? 0,
      totalTime = calcTimeLeftPerStudent(studentReportCard?.time_left) ?? 0
    if (totalTime) {
      totalTime = totalTime < 0 ? 0 : totalTime
    }
    /*CHOICES = (('0', 'Started'), ('1', 'Paused'), ('2', 'Submitted'), ('3', 'Not Started'))*/
    return (
      <>
        {reportStatus === "2" || (reportStatus !== "2" && extTime <= 0) ? null : <Image src={timeline} className={csStyles.timeline_img} />}
        {reportStatus === "2" || (reportStatus !== "2" && !studentReportCard?.time_left) ? "--" : <b>{`${Math.ceil(totalTime)} Mins left`}</b>}
      </>
    )
  }

  const renderRankSection = (index) => {
    let rank = getStudentRank(index)
    return <>{rank > 0 ? <div className={csStyles.rank}>{rank}</div> : "--"}</>
  }

  const renderPopupComponents = (studentData, index) => {
    let displayAboveOrBelow = index + 2 < studentsCSList.length,
      id = studentData.id

    return (
      <>
        {actionItems(studentData)}
        {action.show === SHOW_EXTEND_TIME && action.id === id ? (
          <AcceptExtendTime
            onConfirm={(val) => {
              setInputExtTime(val)
              setAction({ show: SHOW_EXTEND_TIME_CONFIRM, id: id })
            }}
            onCancel={() => setAction({ show: DONT_SHOW, id: 0 })}
            displayAboveOrBelow={displayAboveOrBelow}
          />
        ) : null}
        {/*action.show === SHOW_SUMMON_STUDENT && action.id === id ? (
          <SummonStudent studentData={studentData} onCancel={() => setAction({ show: DONT_SHOW, id: 0 })} displayAboveOrBelow={displayAboveOrBelow} />
        ) : null*/}
      </>
    )
  }

  const actionItems = (studentData) => {
    let status = studentData?.report_card?.case_study_status,
      id = studentData.id

    if (studentData.report_card === null) return null
    else {
      return (
        <>
          <Dropdown>
            <Dropdown.Toggle id={`dropdown_toggle_${id}`} className={`w-100 shadow-none caret-none p-0 ${csStyles.popup}`}>
              <div className={`d-flex align-items-center justify-contents-center ${csStyles.cursor_pointer}`}>
                <b>&#8942;</b>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={csStyles.dropdownItems}>
              {/*Not Submitted, Extend Time*/}
              {status !== "2" && (
                <Dropdown.Item key={`extendTime-${id}`} onClick={(e) => setAction({ show: SHOW_EXTEND_TIME, id })}>
                  Extend time
                </Dropdown.Item>
              )}
              {/*Started, Pause Test*/}
              {status === "0" && (
                <Dropdown.Item key={`pause-${id}`} onClick={(e) => handlePauseTest(id)}>
                  Pause Test
                </Dropdown.Item>
              )}
              {/*Paused, Resume Test*/}
              {status === "1" && (
                <Dropdown.Item key={`resume-${id}`} onClick={(e) => handleResumeTest(id)}>
                  Resume Test
                </Dropdown.Item>
              )}
              <Dropdown.Item key={`view-${id}`} onClick={(e) => handleViewStudentDetails(id)}>
                View Details
              </Dropdown.Item>
              {/*If not already Submitted Test*/}
              {parseInt(status) < 2 && (
                <Dropdown.Item key={`end-${id}`} onClick={(e) => handleEndTest(id)}>
                  End Test
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </>
      )
    }
  }
  const studentRow = (caseStudy, studentData, index) => {
    let cssClasses
    if (caseStudy.grading === true) {
      cssClasses = [csStyles.col_a, csStyles.col_b, csStyles.col_c, csStyles.col_d, csStyles.col_f, csStyles.col_g]
    } else {
      cssClasses = [csStyles.col_a1, csStyles.col_b1, csStyles.col_d1, csStyles.col_e1, "", csStyles.col_g1]
    }

    let studentReportCard = studentData?.report_card,
      border = "",
      backgroundColor = ""

    if ([SHOW_EXTEND_TIME, SHOW_EXTEND_TIME_SUCCESS].includes(action.show) && action.id === studentData.id) {
      border = "border-white"
    }
    if (studentReportCard !== null && studentReportCard.case_study_status === "1") {
      backgroundColor = csStyles.background_color
    }

    return (
      <>
        <div className={`d-flex flex-row ${csStyles.trow} ${csStyles.student_row} ${border} ${backgroundColor} my-1`}>
          <Col className={cssClasses[0]}>{renderNameSection(studentData, index)}</Col>
          <Col className={cssClasses[1]}>{renderProgressSection(studentReportCard)}</Col>
          <Col className={cssClasses[2]}>{renderCurrentScore(studentReportCard)}</Col>
          <Col className={cssClasses[3]}>{renderTimeLeftSection(studentReportCard)}</Col>
          {caseStudy.grading ? <Col className={`${cssClasses[4]} ml-auto`}>{renderRankSection(index)}</Col> : null}
          <Col className={cssClasses[5]}>{renderPopupComponents(studentData, index)}</Col>
        </div>
      </>
    )
  }

  const studentsTableData = () => {
    return studentsCSList.map((studentData, index) => (
      <React.Fragment key={`studentRow_${studentData.id}`}>
        {studentRow(caseStudy, studentData, index)}
        {action.show === SHOW_EXTEND_TIME_SUCCESS && action.id === studentData.id ? (
          <ExtendedTimeSuccess input={inputExtTime} studentData={studentData} displayAboveOrBelow={index + 2 < studentsCSList.length} />
        ) : null}
      </React.Fragment>
    ))
  }

  const caseStudyStudentsList = () => {
    return (
      <>
        <div className={`mx-1 mb-2 w-100`}>
          {studentsTableHeader()}
          {studentsTableData()}
        </div>
      </>
    )
  }

  const caseStudyEndforAll = () => {
    return (
      <>
        <div className={`py-3 px-1 d-flex align-items-center justify-content-between`}>
          <span>{attending_students.length} students attending</span>
          {classroomStore.activeActivityData?.current_active_activity?.resource?.id === resID ? (
            <>
              <BulkEndAction
                success_message="CaseStudy Ended Successfully"
                handleRefresh={getStudentsList}
                title="End Case Study For All"
                triggeredCount={triggeredCount}
                resID={resID}
                action="end_case_study"
                training_id={parseInt(trainingID)}
                sendSocket={sendSocket}
                close={() => {
                  setClassroomStore({ type: VIEW_MODERATION_RESOURCE, payload: classroomStore.activeActivityData?.current_active_activity })
                  setClassroomStore({ type: SET_ACTIVE_ACTIVITY_DATA, payload: {} })
                }}
              />
            </>
          ) : null}
        </div>
      </>
    )
  }

  const caseStudyModals = () => {
    return (
      <>
        {action.show === SHOW_VIEW_DETAILS && (
          <ViewDetails
            {...propsForComponent}
            show={true}
            hide={() => setAction({ show: DONT_SHOW, id: 0 })}
            onError={(errorMsg) => {
              globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: errorMsg ?? "An error occurred" } })
            }}
          />
        )}
        {action.show === SHOW_VIEW_STUDENT_DETAILS && (
          <ReportCard
            resourceId={resID}
            {...propsForComponent}
            caseStudyVersionData={caseStudy}
            show={true}
            onHide={() => setAction({ show: DONT_SHOW, id: 0 })}
            onError={(errorMsg) => {
              if (!errorMsg) {
                errorMsg = "Error Occurred Closing"
              }
              globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: errorMsg } })
            }}
          />
        )}
      </>
    )
  }

  let attending_students = studentsCSList.filter((student) => student.report_card !== null)
  return (
    <>
      {studentsListingLoading ? (
        <div className="d-flex mx-auto">
          <Loader class={`my-auto mx-auto mr-1 ${csStyles.img_loader}`} />
        </div>
      ) : (
        <div className={csStyles.div_main}>
          <CaseStudyDetails caseStudy={caseStudy} viewCaseStudyVersionDetails={viewCaseStudyVersionDetails} triggered_time={resource.triggered_time} />

          {caseStudyEndforAll()}

          {caseStudyStudentsList()}

          {caseStudyModals()}
        </div>
      )}
    </>
  )
}

CaseStudyModeration.propTypes = {
  resource: PropTypes.object.isRequired, //Resource Object
}

export default CaseStudyModeration
