import React, { useContext } from "react"
import PropTypes from "prop-types"
import { postRequest } from "../../../services/rest_service"
//import closeTriggeredResourceAtStudent from "../../resource/closeTriggeredResource"
import Btn from "../../custom/Button"
import { GlobalContext } from "../../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../../stores/global_store/GlobalActions"

const BulkEndAction = (props) => {
  const [globalStore, globalDispatch] = useContext(GlobalContext)
  const bulkAction = async (action) => {
    let formData = {
      action: action,
      trigger_count: props.triggeredCount,
      resource_id: props.resID,
      training_id: props.training_id,
    }
    if (formData.resource_id > 0 && formData.trigger_count > 0 && props.training_id > 0) {
      let response = await postRequest(`/report-card-action/`, formData)
      if (response.success) {
        globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "success", title: props.success_message } })
        handleCloseTriggeredResourceWebsocket()
        props.handleRefresh()
      } else {
        globalDispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: response.msg } })
      }
    }
  }

  const handleCloseTriggeredResourceWebsocket = async () => {
    props.sendSocket && props.sendSocket(JSON.stringify({ event_type: "close_triggered_resource", resource: "pop-quiz", page: "none" }))
    props?.close && props.close()
  }

  return (
    <React.Fragment>
      {props?.type === "text" ? (
        <div
          id="bulk-end-btn"
          onClick={() => {
            bulkAction(props.action)
          }}
          className={`w-100 ${props?.disabled ? "" : ""}`}
        >
          {props.title ? props.title : "End For All"}
        </div>
      ) : (
        <Btn
          type="medium"
          style={{ backgroundColor: "rgb(48, 60, 84)" }}
          className={`px-4 py-1 mr-2`}
          onClick={() => {
            bulkAction(props.action)
          }}
        >
          {props.title ? props.title : "End For All"}
        </Btn>
      )}
    </React.Fragment>
  )
}

BulkEndAction.propTypes = {
  success_message: PropTypes.string.isRequired, //Success Message
  handleRefresh: PropTypes.func.isRequired, //Function to handle Refresh
  title: PropTypes.string.isRequired, //Title
  triggeredCount: PropTypes.number.isRequired, //Triggered Count
  resID: PropTypes.number.isRequired, //Resource ID
  action: PropTypes.string.isRequired, //Action indication
  training_id: PropTypes.number.isRequired, //Training Id
  sendSocket̉: PropTypes.func, //Send Socket
  close: PropTypes.func, //Close Function
}

export default BulkEndAction
