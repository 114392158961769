import React, { useState, useEffect, useRef } from "react"
import Loadable from "@loadable/component"
import PropTypes from "prop-types"

import csStyles from "./casestudy.module.scss"
import utcDate from "../../services/format_date"

import clock from "../../images/svgs/clock.svg"
import ChevronCircleDown from "../../images/svgs/classroom/chevron-circle-down.svg"
import ExclamationTriangle from "../../images/svgs/exclamation-triangle-yellow.svg"
import SubjectiveQuestionResources from "../live_class/SubjectiveQAResources/subjectiveQuestionResources"

const Btn = Loadable(() => import("../custom/Button"))
const Image = Loadable(() => import("../custom/Image"))
const AvatarCircle = Loadable(() => import("../custom/AvatarCircle"))
const HorizontalScroll = Loadable(() => import("../custom/HorizontalScroll"))
export const AcceptExtendTime = (props) => {
  const [input, setInput] = useState(5)
  const [errorMsg, setErrorMsg] = useState("")

  const saveInput = (val) => {
    let value = parseInt(val)
    if (value <= 0 || isNaN(value) || value > 1440) {
      setErrorMsg("Please enter a valid number")
      return
    }
    setErrorMsg("")
    setInput(value)
  }

  const displayClass = props.displayAboveOrBelow ? `${csStyles.disp_extend_time}` : `${csStyles.disp_extend_time1}`

  return (
    <div className={`position-relative`}>
      <div className={`d-flex flex-column border border-white rounded ${displayClass}`}>
        <div className={`p-2 d-inline-flex`}>
          <div className={`${csStyles.w_50}`}>Extend time by:</div>
          <div className={`${csStyles.w_50}`}>
            <input
              type="number"
              min="1"
              max="1440"
              defaultValue={input}
              onChange={(e) => {
                saveInput(e.target.value)
              }}
              className={csStyles.extend_time_input}
            />
            &nbsp;
            <span className={csStyles.extend_time_in}>Mins</span>
          </div>
        </div>
        <span className={`${csStyles.error}`}>&nbsp;{errorMsg}</span>
        <div className={`p-2 d-flex justify-content-end  ${csStyles.border_top}`}>
          <Btn type="medium" onClick={(e) => props.onCancel()}>
            Cancel
          </Btn>
          &nbsp;
          <Btn type="medium" onClick={(e) => props.onConfirm(input)} disabled={errorMsg ? true : false}>
            Confirm
          </Btn>
        </div>
      </div>
    </div>
  )
}
AcceptExtendTime.propTypes = {
  onConfirm: PropTypes.func.isRequired, //Action to taken on Confirmation
  onCancel: PropTypes.func.isRequired, //Cancellation Action
  displayAboveOrBelow: PropTypes.bool.isRequired, //Indication whether to show this render above or below
}

//ExtendedTimeSuccess Component
export const ExtendedTimeSuccess = ({ input, studentData, displayAboveOrBelow }) => {
  //For displaying the last 2 student rows, display the message above
  const displayClass = displayAboveOrBelow ? `${csStyles.disp_top_right}` : `${csStyles.disp_bottom_right}`
  return (
    <div className={`position-relative`}>
      <div className={`d-flex flex-row border border-white rounded p-3 ${displayClass} justify-contents-center`}>
        <div className={`pl-1 pr-4`}>
          <Image src={clock} className={csStyles.disp_extended_time_img} />
        </div>
        <div>
          {input} mins time extended for {studentData.first_name}
        </div>
      </div>
    </div>
  )
}

ExtendedTimeSuccess.propTypes = {
  input: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, //Extended Minutes
  studentData: PropTypes.object.isRequired, //Object representing the student row
  displayAboveOrBelow: PropTypes.bool.isRequired, //Indication whether to show this render above or below
}

/*export const SummonStudent = ({ studentData, onCancel, displayAboveOrBelow }) => {
  let s = displayAboveOrBelow ? "class1" : "class2"
  return (
    <div className={`position-relative`}>
      <div className={`d-flex flex-row border border-white rounded p-2`} style={s}>
        <div className={`mr-auto p-4`}>
          Do you want to summon {studentData.Name} ?
          <br />
          <p>Text</p>
        </div>
        <div className={`p-2 d-flex flex-column justify-content-center`}>
          <Btn onClick={(e) => onCancel()}>Cancel</Btn>
          <Btn>Chat with Student</Btn>
        </div>
      </div>
    </div>
  )
}*/

export const CaseStudyDetails = (props) => {
  const [widthCompletion, setWidthCompletion] = useState(0)
  const [reducingTime, setReducingTime] = useState(0)
  const IntervalID = useRef(null)

  const closeInterval = (interval) => {
    if (interval?.current != null) {
      clearInterval(interval.current)
      interval.current = null
    }
  }
  useEffect(() => {
    setTimerCaseStudyCompletion(props?.caseStudy)
    return () => {
      closeInterval(IntervalID)
    }
  }, [props?.caseStudy])

  const caseStudyCompletionCode = (caseStudyData, runningWithinTimer = 0) => {
    //Prevent code from running when the component is destroyed due to setInterval
    if (runningWithinTimer && IntervalID.current === null) return

    let startDt = utcDate.utcDateTime(props?.triggered_time),
      currentDateTime = Date.now(),
      reducingTime = caseStudyData.time - (currentDateTime - startDt.getTime()) / 60000,
      percentageCompleted = ((currentDateTime - startDt.getTime()) / (caseStudyData.time * 60000)) * 100

    percentageCompleted = percentageCompleted > 100 ? 100 : percentageCompleted
    reducingTime = reducingTime <= 0 ? 0 : reducingTime
    setWidthCompletion(percentageCompleted)
    setReducingTime(reducingTime)
  }

  const setTimerCaseStudyCompletion = (caseStudyData) => {
    if (!caseStudyData?.name) return
    if (!props?.triggered_time) return

    closeInterval(IntervalID)
    caseStudyCompletionCode(caseStudyData)
    IntervalID.current = setInterval(() => {
      caseStudyCompletionCode(caseStudyData, 1)
    }, 60000) //Every Minute
  }

  return (
    <>
      <div className={`d-flex flex-column ${csStyles.case_study_details}`}>
        <div className={`d-flex p-1 ${csStyles.case_study_details_child}`}>
          <div className={`p-2 pl-3 flex-grow-1 flex-column`}>
            <div>{props?.caseStudy?.name}</div>
            <div>
              <button onClick={(e) => props.viewCaseStudyVersionDetails()} className={`${csStyles.btn_view_details}`}>
                View Details
              </button>
            </div>
          </div>
          <div className={`p-2 px-3`}>
            Started at: {utcDate.utcDateTime(props?.triggered_time, "time")} <br />
            {Math.ceil(reducingTime)} mins Left
          </div>
        </div>
        <div className={`${csStyles.div_progressbar}`} style={{ width: `${widthCompletion}%` }}>
          &nbsp;
        </div>
      </div>
    </>
  )
}

CaseStudyDetails.propTypes = {
  caseStudy: PropTypes.object.isRequired, //Case Study Object
  viewCaseStudyVersionDetails: PropTypes.func.isRequired, //Function for view case study version details
  triggered_time: PropTypes.string.isRequired, //Datetime String, indicates when case study was started
}

export const DisplayQuestionAnswers = (props) => {
  const [qOpenClose, setQOpenClose] = useState(true)

  const displayScore = (questionAnswer) => {
    return (
      <div className={`d-flex flex-row mt-2 ${csStyles.view_border_top_grey}`}>
        <div className={`flex-fill p-3`}>
          Score:{" "}
          <span>
            {questionAnswer?.answer_map == null ? "--" : `${questionAnswer?.answer_map?.marks_gained} / ${questionAnswer?.answer_map?.total_marks} Marks`}
          </span>
        </div>
      </div>
    )
  }

  const acceptScore = (idx, questionAnswer) => {
    return (
      <>
        <div className={`mt-2 ${csStyles.view_border_top_grey}`}>
          <div className={`flex-fill p-3`}>
            Score:
            <div className={`d-inline-flex align-items-center`}>
              {questionAnswer?.answer_map && questionAnswer?.answer_map?.id && !props?.published ? (
                <>
                  <input
                    className={`border-0 ml-2 pt-1 pt-0 mr-2 d-flex align-items-center text-white shadow-none ${csStyles.subjective_marks}`}
                    type="number"
                    min="0"
                    value={questionAnswer?.answer_map?.marks_gained ?? ""}
                    onChange={(e) => props.updateQuestionMarks(idx, questionAnswer, e.target.value)}
                    placeholder=""
                  />
                  Out of {questionAnswer?.answer_map?.total_marks} Marks
                </>
              ) : (
                <>
                  <span>
                    {questionAnswer?.answer_map == null
                      ? "--"
                      : `${questionAnswer?.answer_map?.marks_gained} / ${questionAnswer?.answer_map?.total_marks} Marks`}
                  </span>
                </>
              )}
            </div>
          </div>

          {props?.errorMsg ? (
            <div className={`ml-3 px-0 `}>
              <div className="d-inline-flex py-0">
                <Image className={`mt-1 mr-1 ${csStyles.h_12}`} src={ExclamationTriangle} />
                <p className={`mb-0 ${csStyles.error1}`}>{props?.errorMsg}</p>
              </div>
            </div>
          ) : null}
        </div>
      </>
    )
  }

  let answer,
    alphabets = "ABCDEFGHIJKLMNOPQRSTUWXYZ"
  if (props.type === "student-view") {
    answer = props.questionAnswer?.subjective_answers?.[0]?.answer ?? "--"
  }

  return (
    <>
      <div className={`mt-2 d-flex flex-row`}>
        <div className={`mr-2 mt-3 ${csStyles.view_number}`}>{props.index + 1}</div>
        <div className={`d-flex overflow-hidden flex-column ml-1 mr-4 py-2 w-100 ${csStyles.view_question_block}`}>
          {/*Question Block*/}
          <div className={`d-flex flex-row`}>
            <div className={`flex-fill p-2`}>
              {props.questionAnswer.question_type === "2" /*SubjectiveQA*/ ? (
                <>
                  <div className={`mb-1 px-2 ${csStyles.view_qa}`}>Question</div>
                  <p className={`p-2 mb-0`} dangerouslySetInnerHTML={{ __html: props.questionAnswer.title }} />
                  {props.questionAnswer.description.length > 0 ? (
                    <p className={`px-2 pb-2 mb-0`} dangerouslySetInnerHTML={{ __html: props.questionAnswer.description }} />
                  ) : null}
                </>
              ) : (
                <>
                  {/*ObjectiveQA*/}
                  <div className={`p-2 ${csStyles.view_gray_background_row}`}>{props.questionAnswer.title}</div>
                </>
              )}
            </div>
            <div className={`py-3`}>
              <Image
                onClick={(e) => setQOpenClose((openClose) => !openClose)}
                src={ChevronCircleDown}
                className={`mr-2 ${qOpenClose === false ? csStyles.up_arrow_circle : csStyles.down_arrow_circle}`}
              />
            </div>
          </div>
          {/*Answer Block*/}
          <div className={`${qOpenClose === false ? "d-none" : ""}`}>
            {props.questionAnswer.question_type === "2" /*SubjectiveQA*/ ? (
              <>
                {props.type === "case-study-view" ? null : (
                  <>
                    <div className={`px-2`}>
                      <p className={`mb-2 px-2 ${csStyles.view_qa}`}>Answer</p>
                      <div className={`p-3 ${csStyles.view_gray_background_row_alt}`}>
                        <p dangerouslySetInnerHTML={{ __html: answer }} />
                        <HorizontalScroll className={`d-inline-flex w-100`} scrollId={props.questionAnswer.id}>
                          {props.questionAnswer?.subjective_answers[0]?.student_resources.length > 0 && (
                            <div className={`d-flex align-items-center w-100 overflow-scroll`}>
                              {props.questionAnswer?.subjective_answers[0]?.student_resources?.map((resource, index) => {
                                return <SubjectiveQuestionResources key={`resource_${resource?.id}`} resource={resource} />
                              })}
                            </div>
                          )}
                        </HorizontalScroll>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {/*ObjectiveQA*/}
                <div className={`px-2 d-flex flex-column`}>
                  {props.questionAnswer.student_options?.map((objectiveAnswer, idx) => (
                    <div key={`student_options_${idx}`} className={`d-flex flex-row py-1`}>
                      {props.type === "student-view" ? (
                        <div className={`mr-2 py-2 ${csStyles.w_25}`}>
                          {objectiveAnswer.is_selected ? (
                            <AvatarCircle size={`26px`} name={props?.studentName} avatar={props?.studentProfilePic} />
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </div>
                      ) : null}
                      <div className={`mr-2 py-2`}>
                        <span className={`${csStyles.view_number1}`}>{alphabets[idx]}</span>
                      </div>
                      <div
                        className={`d-flex flex-fill align-items-center mr-4 px-3 p-1 ${
                          objectiveAnswer.is_correct ? csStyles.view_green_background_row : csStyles.view_gray_background_row
                        }`}
                      >
                        {objectiveAnswer.name}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {props.type === "case-study-view" ? (
              <>
                {props.caseStudy?.grading === false ? null : (
                  <div className={`d-flex flex-row mt-2 ${csStyles.view_border_top_grey}`}>
                    <div className={`flex-fill p-3`}>Score: {props.questionAnswer.answer_map?.total_marks ?? "--"} marks</div>
                  </div>
                )}
              </>
            ) : null}

            {props.type === "student-view" ? (
              <>
                {props.questionAnswer?.answer_map == null || props?.caseStudyVersionData?.grading === false ? null : ( //If no score, then dont display
                  <>{props?.questionAnswer?.question_type === "2" ? acceptScore(props?.index, props?.questionAnswer) : displayScore(props.questionAnswer)}</>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

DisplayQuestionAnswers.propTypes = {
  type: PropTypes.string.isRequired, //Type(case-study-view/student-view) is used to identify source which is triggering this
  index: PropTypes.number.isRequired, //Index number of the array of Question&Answers
  questionAnswer: PropTypes.object.isRequired, // One Question&Answer Object
  caseStudy: PropTypes.object.isRequired, //Object holding the case study details
  errorMsg: PropTypes.string, //If set, this is used display the Error Message
  updateQuestionMarks: PropTypes.func, //In Student-view type, this is used to update the Msrks
  published: PropTypes.any, //In the case of Student-view Type, we determine whether to allow editing of marks giving
  studentName: PropTypes.string.isRequired, //Student Name
  studentProfilePic: PropTypes.string, //Student Profile Pic
}
